

/* @tailwind base;
@tailwind components;
@tailwind utilities; */
body{
    overflow-x: hidden !important;
  }
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
  }
  
  a {
    color: #ff7f5d;
    text-decoration: none;
  }
  
  a:hover {
    color: #ffa790;
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Raleway", sans-serif;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #ff7f5d;
    border-top-color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1s linear infinite;
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #CF1919;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #ff9f86;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    background: rgba(21, 5, 23, 0.25);
    transition: all 0.5s;
    z-index: 997;
    position: relative;
    height: 70px;
  }
  
  @media (max-width: 992px) {
    #header {
      height: 60px;
    }
  }
  
  #header.fixed-top,
  #header.header-inner-pages {
    background: rgba(21, 5, 23, 0.8);
  }
  
  #header.fixed-top {
    position: fixed;
  }
  
  #header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  
  #header .logo a {
    color: #fff;
  }
  
  #header .logo img {
    max-height: 40px;
  }
  
  .scrolled-offset {
    margin-top: 70px;
  }
  
  @media (max-width: 991px) {
    .scrolled-offset {
      margin-top: 60px;
    }
  }
  
  /*--------------------------------------------------------------
  # Header Social Links
  --------------------------------------------------------------*/
  .header-social-links {
    padding-left: 20px;
  }
  
  .header-social-links a {
    color: rgba(255, 255, 255, 0.6);
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }
  
  .header-social-links a i {
    line-height: 0;
  }
  
  .header-social-links a:hover {
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .header-social-links {
      padding: 0 15px 0 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar>ul>li {
    white-space: nowrap;
    padding: 10px 12px;
  }
  
  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
    transition: 0.3s;
    color: white;
    position: relative;
  }
  
  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  .navbar>ul>li>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -7px;
    left: 0;
    background-color: #ff7f5d;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .navbar a:hover:before,
  .navbar li:hover>a:before,
  .navbar .active:before {
    visibility: visible;
    width: 100%;
  }
  
  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 12px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: none;
    font-weight: 500;
    color: #150517;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: #ff7f5d;
  }
  
  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #150517;
  }
  
  .navbar-mobile>ul>li {
    padding: 0;
  }
  
  .navbar-mobile a:hover:before,
  .navbar-mobile li:hover>a:before,
  .navbar-mobile .active:before {
    visibility: hidden;
  }
  
  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #ff7f5d;
  }
  
  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: #ff7f5d;
  }
  
  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 100vh;
    background: url("https://iili.io/HrlbNe9.png") top center;
    background-size: cover;
    position: relative;
    margin-bottom: -70px;
  }
  
  @media (max-width: 992px) {
    #hero {
      margin-bottom: -60px;
    }
  }
  
  #hero:before {
    content: "";
    background: rgba(21, 5, 23, 0.5) linear-gradient(rgba(26, 0, 33, 0.5) 0%, rgba(26, 0, 33, 0.5) 5%, rgba(38, 6, 51, 0.5) 40%, rgba(147, 47, 69, 0.7) 76%, rgba(236, 97, 93, 0.7) 94%, rgba(236, 97, 93, 0.5) 100%) repeat scroll 0% 0%;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero .hero-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 15px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  #hero h1 {
    margin: 0;
    font-size: 40px;
    font-weight: 900;
    line-height: 56px;
    text-transform: uppercase;
    color: #fff;
    font-family: "Kalam";
  }
  
  #hero h2 {
    color: #eee;
    margin: 15px 0 0 0;
    font-size: 19px;
    width: 66%;
    
  }
  
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 40px;
    border-radius: 4px;
    transition: 0.5s;
    margin-top: 30px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  #hero .btn-get-started:hover {
    background: #CF1919;
    border: 2px solid #CF1919;
  }
  
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 768px) {
    #hero h1 {
      font-size: 30px;
      line-height: 36px;
    }
  
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #fafafa;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #CF1919;
    bottom: 0;
    left: calc(50% - 25px);
  }
  
  .section-title p {
    margin-bottom: 0;
    color: #919191;
    font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .about .content ul li+li {
    margin-top: 10px;
  }
  
  .about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #ff7f5d;
    line-height: 1;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #ff7f5d;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #ff7f5d;
  }
  
  .about .content .btn-learn-more:hover {
    background: #CF1919;
    color: #fff;
    text-decoration: none;
  }
  
  /*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
  .counts {
    padding: 0;
  }
  
  .counts .counters span {
    font-size: 48px;
    display: block;
    color: #ff7f5d;
  }
  
  .counts .counters p {
    padding: 0;
    margin: 0 0 20px 0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # Our Values
  --------------------------------------------------------------*/
  .our-values .card {
    border: 0;
    padding: 160px 20px 20px 20px;
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .our-values .card-body {
    z-index: 10;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    transition: ease-in-out 0.4s;
    border-radius: 5px;
  }
  
  .our-values .card-title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .our-values .card-title a {
    color: #150517;
  }
  
  .our-values .card-text {
    color: #5e5e5e;
  }
  
  .our-values .read-more a {
    color: #777777;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    transition: 0.4s;
  }
  
  .our-values .read-more a:hover {
    text-decoration: underline;
  }
  
  .our-values .card:hover .card-body {
    background: #CF1919;
  }
  
  .our-values .card:hover .read-more a,
  .our-values .card:hover .card-title,
  .our-values .card:hover .card-title a,
  .our-values .card:hover .card-text {
    color: #fff;
  }
  
  /*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
  .clients {
    padding: 15px 0;
    text-align: center;
  }
  
  .clients img {
    /* max-width: 45%; */
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;
    filter: grayscale(100);
  }
  
  .clients img:hover {
    filter: none;
    transform: scale(1.15);
  }
  
  @media (max-width: 768px) {
    .clients img {
      max-width: 40%;
    }
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
    height: 100%;
  }
  
  .services .icon-box:hover {
    transform: translateY(-5px);
  }
  
  .services .icon {
    position: absolute;
    left: -20px;
    top: calc(50% - 30px);
  }
  
  .services .icon i {
    font-size: 64px;
    line-height: 1;
    transition: 0.5s;
  }
  
  .services .title {
    margin-left: 40px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .title a {
    color: #111;
  }
  
  .services .icon-box:hover .title a {
    color: #ff7f5d;
  }
  
  .services .description {
    font-size: 14px;
    margin-left: 40px;
    line-height: 24px;
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }
  
  .testimonials .testimonial-item {
    box-sizing: content-box;
    min-height: 320px;
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    margin: -40px 0 0 40px;
    position: relative;
    z-index: 2;
    border: 6px solid #fff;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 45px;
    color: #111;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0 0 0 45px;
  }
  
  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
    color: #ffbbaa;
    font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 15px 0 15px;
    padding: 20px 20px 60px 20px;
    background: #fff;
    position: relative;
    border-radius: 6px;
    position: relative;
    z-index: 1;
  }
  
  .testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #ff7f5d;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #CF1919;
  }
  
  /*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
  .cta {
    background: #280a2c;
    background-size: cover;
    padding: 60px 0;
  }
  
  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: #fff;
  }
  
  .cta .cta-btn {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 35px;
    border-radius: 4px;
    transition: 0.5s;
    margin-top: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  .cta .cta-btn:hover {
    background: #CF1919;
    border: 2px solid #CF1919;
  }
  
  /*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
  .portfolio #portfolio-flters {
    list-style: none;
    margin-bottom: 20px;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    margin: 0 15px 10px 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
    color: #ff7f5d;
  }
  
  .portfolio #portfolio-flters li.filter-active::before,
  .portfolio #portfolio-flters li.filter-active::after {
    color: #ff7f5d;
  }
  
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio .portfolio-item .portfolio-img {
    overflow: hidden;
  }
  
  .portfolio .portfolio-item .portfolio-img img {
    transition: all 0.5s ease-in-out;
  }
  
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 15px;
    bottom: 0;
    z-index: 3;
    right: 15px;
    transition: all ease-in-out 0.3s;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 15px;
  }
  
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
  }
  
  .portfolio .portfolio-item .portfolio-info p {
    color: white;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link,
  .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #fff;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link:hover,
  .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: #ff7f5d;
  }
  
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
  }
  
  .portfolio .portfolio-item:hover .portfolio-img img {
    transform: scale(1.2);
  }
  
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding-top: 40px;
  }
  
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #ff7f5d;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ff7f5d;
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(21, 5, 23, 0.08);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
  .team .member {
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  }
  
  .team .member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .team .member .social {
    position: absolute;
    left: 0;
    bottom: 30px;
    right: 0;
    opacity: 0;
    transition: ease-in-out 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .team .member .social a {
    transition: color 0.3s;
    color: #150517;
    margin: 0 3px;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    background: rgba(255, 127, 93, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out 0.3s;
    color: #fff;
  }
  
  .team .member .social a i {
    line-height: 0;
  }
  
  .team .member .social a:hover {
    background: #CF1919;
  }
  
  .team .member .social i {
    font-size: 18px;
  }
  
  .team .member .member-info {
    padding: 25px 15px;
  }
  
  .team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #150517;
  }
  
  .team .member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
  }
  
  .team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    line-height: 26px;
    color: #777777;
  }
  
  .team .member:hover .social {
    opacity: 1;
    bottom: 15px;
  }
  
  /*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
  .pricing .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .pricing h3 {
    font-weight: 400;
    margin: -20px -20px 20px -20px;
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #777777;
    background: #f8f8f8;
  }
  
  .pricing h4 {
    font-size: 36px;
    color: #ff7f5d;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }
  
  .pricing h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  
  .pricing h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
  
  .pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
  
  .pricing ul li {
    padding-bottom: 16px;
  }
  
  .pricing ul i {
    color: #ff7f5d;
    font-size: 18px;
    padding-right: 4px;
  }
  
  .pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
  }
  
  .pricing .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    background: #f8f8f8;
    text-align: center;
  }
  
  .pricing .btn-buy {
    background: #CF1919;
    display: inline-block;
    padding: 8px 35px 9px 35px;
    border-radius: 4px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    transition: 0.3s;
  }
  .btn-buy-thakyou {
    background: #CF1919;
    display: inline-block;
    padding: 8px 35px 9px 35px;
    border-radius: 4px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    transition: 0.3s;
  }
  
  .pricing .btn-buy:hover {
    background: #CF1919;
  }
  
  .pricing .featured h3 {
    color: #fff;
    background: #CF1919;
  }
  
  .pricing .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: #CF1919;
    color: #fff;
  }
  
  /*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
  .faq .faq-list {
    padding: 0 100px;
  }
  
  .faq .faq-list ul {
    padding: 0;
    list-style: none;
  }
  
  .faq .faq-list li+li {
    margin-top: 15px;
  }
  
  .faq .faq-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
  }
  
  .faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
  }
  
  .faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: #ffa790;
  }
  
  .faq .faq-list .icon-show,
  .faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  .faq .faq-list .icon-show {
    display: none;
  }
  
  .faq .faq-list a.collapsed {
    color: #343a40;
  }
  
  .faq .faq-list a.collapsed:hover {
    color: #ff7f5d;
  }
  
  .faq .faq-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .faq .faq-list a.collapsed .icon-close {
    display: none;
  }
  
  @media (max-width: 1200px) {
    .faq .faq-list {
      padding: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info {
    width: 100%;
    background: #fff;
  }
  
  .contact .info i {
    font-size: 20px;
    color: #ff7f5d;
    float: left;
    width: 44px;
    height: 44px;
    background: #fff8f6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #150517;
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #62176b;
  }
  
  .contact .info .email,
  .contact .info .phone {
    margin-top: 40px;
  }
  
  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    background: #CF1919;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    background: #fff;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 4px;
    box-shadow: none;
    font-size: 14px;
  }
  
  .contact .php-email-form input:focus,
  .contact .php-email-form textarea:focus {
    border-color: #ff7f5d;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: #CF1919;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: #ff9377;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background: #fafafa;
    min-height: 40px;
  }
  
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 500;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
  }
  
  .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #3b0e41;
    content: "/";
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  
  
  
  
  
  /* image on hover css */
  p{
    font-size: 15px;
  }
  
  p strong{
    font-family: Lato;
    font-size: 40px;
  }
  
  .item{
    width: 100%;
    height: 400px;
    background-color: white;
    position: relative;
    display: inline-block;
    margin: 1% 2% 1% 0%;
    overflow: hidden;
    box-shadow: 2px 3px 10px #444444;
  }
  
  .item .overlay{
    color: #000;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    transition: all 0.5s ease;
    position: absolute;
    top: 0; 
    bottom: 0;
  }
  
  /* effect-clean*/
  .clean .overlay span:nth-child(1){
  
    position: absolute;
    left: 5%;
    top: 14%;
    font-size: 80px;
    font-weight: bold;
    font-family: coustard;
    transform: translateX(-50%);
  }
  
  .clean .overlay span:nth-child(2){
    position: absolute;
    top: 22%;
    left: 15rem;
    font-size: 17px;
    transform: translateX(-50%);
  }
  
  .clean .overlay span:nth-child(3){
    height: 3px;
    background-color: #000;
    position: absolute;
    top: 34%;
    left: 39px;
  }
  .ovelayp{
    width: 60rem;
  
   
   
  }
  .clean .overlay:hover{
    opacity: 0.93;
  }
  
  .clean .overlay:hover span:nth-child(1){
    animation: slide 0.4s;
  }
  
  .clean .overlay:hover span:nth-child(2){
    animation: slide 0.7s;
  }
  
  .clean .overlay:hover span:nth-child(3){
    animation: line 0.5s forwards;
  }
  
  @keyframes slide{
    0%   {transform:translateX(-10%);}
    100% {transform:translateX(-50%);}
  }
  
  @keyframes line{
    0%   {width: 0;}
    100% {width: 50%}
  }
  
  /* effect-uncover*/
  
  .uncover .overlay span:nth-child(1){
    position: absolute;
    left: 50%;
    top: 35%;
    font-size: 80px;
    font-weight: bold;
    font-family: coustard;
    transform: translateX(-50%);
    opacity: 0;
  }
  
  .uncover .overlay span:nth-child(2){
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 17px;
    transform: translateY(-50%);
    transform: translateX(-50%);
    opacity: 0;
  }
  
  .uncover .overlay span:nth-child(3){
    height: 30px;
    background-color: #000;
    position: absolute;
    top: 38%;
    opacity: 0;
  }
  
  .uncover .overlay span:nth-child(4){
    height: 30px;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: 25%;
    opacity: 0;
  }
  
  .uncover .overlay:hover{
    opacity: 0.9;
  }
  
  .uncover .overlay:hover span:nth-child(1){
    animation: fadein 0.6s forwards;
      animation-delay: 0.5s;
  }
  
  .uncover .overlay:hover span:nth-child(2){
    animation: fadein 0.6s forwards;
    animation-delay: 0.75s;
  }
  
  .uncover .overlay:hover span:nth-child(3){
    animation: draw-left 0.7s;
  }
  
  .uncover .overlay:hover span:nth-child(4){
    animation: draw-right 0.7s;
    animation-delay: 0.6s;
  }
  
  @keyframes draw-left{
    0%   {
      opacity: 0; 
      width: 0px;
      left: 75%}
    50% {
      opacity: 1;
      left: 25%;
      width: 200px;}
    
    100% {
      opacity: 0;
      width: 0px;
      left: 25%;}
  }
  
  @keyframes draw-right{
    0%   {
      opacity: 0; 
      width: 0px;
  }
    50% {
      opacity: 1;
      width: 200px;
     }
    
    100% {
      opacity: 0;
      width: 0px;
      }
  }
  
  @keyframes fadein{
    0%   {
      opacity: 0;} 
    100%   {
      opacity: 1; }
  }
  
  /* effect-explode */
  .explode .overlay span:nth-child(1){
    position: absolute;
    left: 10%;
    top: 45%;
    font-size: 200px;
    font-weight: bold;
    font-family: coustard;
    transform: translateX(-50%);
    opacity: 0;
  }
  
  .explode .overlay span:nth-child(2){
    position: absolute;
    top: 80%;
    left: 10%;
    font-size: 17px;
    opacity: 0;
  }
  
  .explode .overlay span:nth-child(3){
    position: absolute;
    top: 90%;
    left: 0;
    height: 60px;
    background-color: #000;
  }
  
  .explode .overlay:hover{
    opacity: 0.9;
  }
  
  .explode .overlay:hover span:nth-child(1){
    animation: focus 0.4s forwards;
    animation-delay: 0.2s;
  }
  
  .explode .overlay:hover span:nth-child(2){
    animation: focus 0.4s forwards;
    animation-delay: 0.4s;
  }
  
  .explode .overlay:hover span:nth-child(3){
    animation: block 0.3s forwards;
    animation-delay: 0.15s;
  }
  
  @keyframes focus{
    0%   {
      transform:scale(1.4) translateX(-30%);
      filter: blur(10px);
      opacity: 0;
    }
    100% {
      transform:scale(1) translateX(0%);
      filter: blur(0px);
      opacity: 1;
    }
  }
  
  @keyframes block{
    0%   {
      width:0;
      filter: blur(5px);
      opacity: 0;
    }
    100% {
      width: 100%;
      filter: blur(0px);
      opacity: 1;
    }
  }
  
  /* footer css */
  #footer {
    background: #0c0c0c;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-top .footer-info {
    margin-bottom: 15px;
    background: #191919;
    color: #fff;
    border-top: 4px solid #cc1616;
    text-align: center;
    padding: 30px 20px;
  }
  
  #footer .footer-top .footer-info h3 {
    font-size: 36px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #262626;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #cc1616;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ed5b5b;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    color: #eb4444;
  }
  
  #footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4;
  }
  
  #footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
  }
  
  #footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #cc1616;
    color: #fff;
    transition: 0.3s;
    border-radius: 4;
  }
  
  #footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #e82d2d;
  }
  
  #footer .copyright {
    border-top: 1px solid #262626;
    text-align: center;
    padding-top: 30px;
  }
  
  #footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
  
  #footer .credits a {
    color: #e82d2d;
  }
  
  @media (max-width: 575px) {
    #footer .footer-top .footer-info {
      margin: -20px 0 30px 0;
    }
  }
  
  
  
  /* clients carousal */
  .marquee-wrapper {
  
    /* overflow: hidden; */
    /* height: 8rem; */
  }
  /* .marquee-wrapper {
    overflow: hidden;
  } */
  
  .marquee {
    width: 100%;
    display: flex;
    animation-name: marquee;
    animation-duration:20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction:normal;
    transform: translateX(0);
    
   
  }
  .marquee>img {
    width: 19%;
  
  }
  .CONT:hover {
    animation-play-state: paused;
  }
  
  @keyframes marquee {
    from {transform: translateX(0);}
    to {transform: translateX(-100%);}
  }
  .CONT{
    width: 250px;
    margin-right: 20px;
    height: 100px;
    background-color: white;
    margin: 0;
      position: relative;
      top: 50%;
      /* transform: translateY(-50%); */
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    
    }
    
  
  /* our services pricing  */
  .our_pricing .box {
    width: 100%;
    padding: 40px 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
  }
  .box {
    transition: 0.3s;
  }
  
  .our_pricing .box:hover {
    transform: scale(1.1);
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  }
  .box:hover {
    transform: scale(1.1);
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  }
  
  .our_pricing h3 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .our_pricing .price {
    font-size: 36px;
    color: #444444;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
  }
  
  .our_pricing .price sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  
  .our_pricing .price span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
  
  .our_pricing img {
    padding: 30px 40px;
  }
  
  .our_pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 25px;
  }
  
  .our_pricing ul li {
    padding-bottom: 10px;
  }
  
  .our_pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
  }
  
  
  
  .our_pricing .featured {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: #4154f1;
    color: #fff;
  }
  
  
  /* about2 section */
  .about .content {
    background-color: #f6f9ff;
    padding: 40px;
  }
  
  .about h3 {
    font-size: 14px;
    font-weight: 700;
    color: #CF1919;
    text-transform: uppercase;
  }
  
  .about h2 {
    font-size: 24px;
    font-weight: 700;
    /* color: black; */
  }
  
  .about p {
    margin: 15px 0 30px 0;
    line-height: 24px;
  }
  
  
  
  .about .btn-read-more span {
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
  }
  
  .about .btn-read-more i {
    margin-left: 5px;
    font-size: 18px;
    transition: 0.3s;
  }
  
  .about .btn-read-more:hover i {
    transform: translateX(5px);
  }
  
  /* testimonials */
  .container_1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr;
    padding: 10px;
    gap: 1em;
    max-width: 1440px;
    margin: auto;
    width: 87%;
  }
  
  .card_1 {
    padding: 20px;
    border-radius: 10px;
  }
  
  .card_1 .header {
    display: flex;
    height: 50px;
    align-items: center;
    gap: 10px;
  }
  
  .card_1 > * {
    padding: 10px;
    line-height: 1.5;
  }
  
  .card_1 .header .name {
    font-size: 1.2em;
  }
  
  .card_1 .avatar {
    border-radius: 50%;
  }
  
  .card_1 .title {
    font-size: 1.7em;
  }
  
  .card_1 .status {
    color: inherit;
    opacity: 0.5;
  }
  
  .card_1 .quote {
    font-size: 1.1em;
    color: inherit;
    opacity: 0.7;
  }
  
  .card_1 .quote::before {
    content: open-quote;
  }
  
  .card_1 .quote::after {
    content: close-quote;
  }
  
  .card_1:nth-of-type(1) {
    background: hsl(263, 55%, 52%);
    color: hsl(0, 0%, 100%);
    grid-column: 1 / 3;
    background-image: url('https://assets.codepen.io/5126815/bg-pattern-quotation.svg');
    background-repeat: no-repeat;
    background-position: 80% 0%;
  }
  
  .card_1:nth-of-type(2) {
    background: hsl(217, 19%, 35%);
    color: hsl(0, 0%, 100%);
  }
  
  .card_1:nth-of-type(5) {
    background: aliceblue;
    grid-column: 4;
    grid-row: 1 / 3;
  }
  
  .card_1:nth-of-type(3) {
    background: ghostwhite;
  }
  
  .card_1:nth-of-type(4) {
    background: hsl(219, 29%, 14%);
    color: hsl(0, 0%, 100%);
    background-image: url('https://assets.codepen.io/5126815/bg-pattern-quotation.svg');
    background-repeat: no-repeat;
    background-position: 80% 0%;
    grid-column: 2 / 4;
  }
  
  @media(max-width: 800px) {
    .container_1 {
        display: flex;
        flex-direction: column;
    }
  }
  
  
  /* features  */
  .features .nav-tabs {
    border: 0;
  }
  
  .features .nav-link {
    border: 1px solid #b5ccdb;
    padding: 15px;
    transition: 0.3s;
    color: #15222b;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .features .nav-link i {
    padding-right: 15px;
    font-size: 48px;
  }
  
  .features .nav-link h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  
  .features .nav-link:hover {
    color: #CF1919;
  }
  
  .features .nav-link.active {
    background: #CF1919;
    color: #fff;
    border-color: #CF1919;
  }
  
  @media (max-width: 768px) {
    .features .nav-link i {
      padding: 0;
      line-height: 1;
      font-size: 36px;
    }
  }
  
  @media (max-width: 575px) {
    .features .nav-link {
      padding: 15px;
    }
  
    .features .nav-link i {
      font-size: 24px;
    }
  }
  
  .features .tab-content {
    margin-top: 30px;
  }
  
  .features .tab-pane h3 {
    font-weight: 600;
    font-size: 22px;
  }
  
  .features .tab-pane ul {
    list-style: none;
    padding: 0;
  }
  
  .features .tab-pane ul li {
    padding-bottom: 10px;
  }
  
  .features .tab-pane ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #CF1919;
  }
  
  .features .tab-pane p:last-child {
    margin-bottom: 0;
  }
  
  /* why us vidio */
  .why-us {
    background: whitesmoke;
    padding: 0;
  }
  
  .why-us .content {
    padding: 60px 100px 0 100px;
  }
  
  .why-us .content h3 {
    font-weight: 400;
    font-size: 34px;
  }
  
  .why-us .content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
  }
  
  .why-us .content p {
    font-size: 15px;
    color: #959595;
  }
  
  .why-us .video-box {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 400px;
    position: relative;
  }
  
  .why-us .accordion-list {
    padding: 0 100px 60px 100px;
  }
  
  .why-us .accordion-list ul {
    padding: 0;
    list-style: none;
  }
  
  .why-us .accordion-list li+li {
    margin-top: 15px;
  }
  
  .why-us .accordion-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
  }
  
  .why-us .accordion-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 30px;
    outline: none;
    cursor: pointer;
  }
  
  .why-us .accordion-list span {
    color: #7cc576;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
  }
  
  .why-us .accordion-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .why-us .accordion-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  .why-us .accordion-list .icon-show {
    display: none;
  }
  
  .why-us .accordion-list a.collapsed {
    color: #343a40;
  }
  
  .why-us .accordion-list a.collapsed:hover {
    color: #7cc576;
  }
  
  .why-us .accordion-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .why-us .accordion-list a.collapsed .icon-close {
    display: none;
  }
  
  .why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#CF1919 50%, rgba(124, 197, 118, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .why-us .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .why-us .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(209, 71, 61, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .why-us .play-btn:hover::after {
    border-left: 15px solid #ce4634;
    transform: scale(20);
  }
  
  .why-us .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
  @media (max-width: 1024px) {
  
    .why-us .content,
    .why-us .accordion-list {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  @media (max-width: 992px) {
    .why-us .content {
      padding-top: 30px;
    }
  
    .why-us .accordion-list {
      padding-bottom: 30px;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  
  
           
  /* modal vido pop up */
  .modal__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(28, 28, 28, 0.19);
    backdrop-filter: blur(6px);
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    z-index: 100;
  }
  
  .modal__align {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .modal__content {
    width: 800px;
    height: 500px;
    box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
      0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
      0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
      0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
    border-radius: 20px;
    background: transparent;
    color: #000;
    margin: 0rem 4rem;
  }
  
  .modal__close {
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    bottom: 50px;
    width: 32px;
    height: 32px;
    padding: 0;
  }
  
  .modal__video-align {
    display: flex;
    position: relative;
    bottom: 37px;
  }
  
  .modal__video-style {
    border-radius: 20px;
    z-index: 100;
  }
  
  .modal__spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modal__spinner {
    animation: spin 2s linear infinite;
    font-size: 40px;
    color: #1b6aae;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media screen and (max-width: 800px) {
    .modal__content {
      margin: 0rem 1rem;
      width: 100%;
    }
    .modal__video-style {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 499px) {
    .modal__content {
      background: transparent;
      height: auto;
    }
    .modal__video-align {
      bottom: 0px;
    }
    .modal__video-style {
      height: auto;
    }
  }
  
  /* thank u */
  .email-signup-thankyou{
    font-family:sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #333;
    padding:6%;
    height: 100vh;
    
    .content{
      margin: auto;  /* Magic! */
      max-width:700px;
      color:#333;
      box-shadow: 0 3px 6px rgba(0,0,0,0.55), 0 3px 6px rgba(0,0,0,0.23);
      background:url('https://www.crafta.co.il/images/paper-plane.gif') no-repeat #fff;
      background-position: right 5px bottom 5px;
      background-size: 10em;
      text-align:center;
      position: relative;
      padding:10%;
      border-radius:5px;
  
      .left-hole,.right-hole{
        position: absolute;
        width:20px; height:20px;
        background:#333;
        border-radius:50%;
        top:15px;
      }
      .left-hole{
        left:15px;
        top:10px;
      }
      .right-hole{
        right:15px;
        top:10px;
      }
  
      .main-content{
        > h1 {
          color:#333;
          text-transform:uppercase;
          margin-top:-2%;
          font-size:2.5em;
          font-weight:900;
        }
      }
    }
  }
  
  /* hover card */
  /* .flip-card {
    background-color: transparent;
  
    height: 67vh;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
   
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
  
  .flip-card:focus {
      outline: 0;
  }
  
  .flip-card:hover .flip-card-inner,
  .flip-card:focus .flip-card-inner{
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .flip-card-front {
   
    color: black;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .flip-card-back {
  
    transform: rotateY(180deg);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  .pricing .box{
    box-shadow:  8px 8px 19px #c7c7c7,
    -8px -8px 19px #f9f9f9;
    border-radius: 15px;
  }
  
  /* .img-fluid{
    border-radius: 15px;
  } */
  
  .market{
    margin:auto;
    width: 80%;
   
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  /* .marquecard{
    height: 120px;
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  } */
  
  
  /* our clients 2 */
  /* .swiper {
    overflow: none;
  } */
  
  /* .Typewriter__wrapper{
    font-size: 40px;
    font-weight: bolder;
    color: white;
    font-family: "athleticsmedium";
  
  } */
  
  @font-face {
    font-family: "athleticsmedium";
    src: url("https://cdn.shopify.com/s/files/1/0749/0610/8198/files/Athletics_Medium.woff2") format("woff2");
    }
  
  
  
    /* ribbon card  */
    .cr {
      width: 200px;
      padding: 16px;
      position: absolute;
      text-align: center;
      color: #f0f0f0;
    }
    
  
    
  
    
    .cr-top    { top: 25px; }
    .cr-bottom { bottom: 25px; }
  .cr-left   { left: -50px; }
  .cr-right  { right: -50px; }
  
  
  
  .cr-top.cr-left,
  .cr-bottom.cr-right {
    transform: rotate(-45deg);
  }
  
  
  
  
  
  .cr-white     { background-color: #f0f0f0; color: #444; }
  .cr-black     { background-color: #333; }
  .cr-grey      { background-color: #999; }
  .cr-blue      { background-color: #39d; }
  .cr-green     { background-color: #2c7; }
  .cr-turquoise { background-color: #1b9; }
  .cr-purple    { background-color: #95b; }
  .cr-red       { background-color: #e43; }
  .cr-orange    { background-color: #e82; }
  .cr-yellow    { background-color: #ec0; }
  
  
  /* testimonials two css */
  .testimonials {
    background: #f1f5f8;
    padding: 2rem 1rem;
    
  }
  
  .testimonials .testimonial {
    background: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
   border-radius: 20px;
  }
  
  .testimonial > img {
    height: 6rem;
    width: 6rem;
    object-fit: contain;
    margin-bottom: 1rem;
    border-radius: 50%;
  }
  
  .testimonial .personal-info {
    display: flex;
    flex-direction: column;
  
    min-height: 8rem;
  }
  
  /* .testimonial .personal-info > * {
    margin: 0.4rem 0;
  } */
  
  .testimonial .description {
    width: 80%;
    text-align: left;
    min-height: 10rem;
  }
  
  .names{
    font-size: 17px;
  }
  
  .custom-tooltip {
    --bs-tooltip-bg: var(--bd-violet-bg);
    --bs-tooltip-color: var(--bs-white);
  }
  
  .partner-cont{
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-around;
  }
  
  
  @media only screen and (min-width: 0px) and (max-width: 800px) {
    
  .partner-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin: auto;
   flex-direction: column !important;
  }
  .CONT{
    margin-bottom: 20px;
  }
  #pricing_box{
    margin-bottom: 50px;
  }
  #pricegrid{
    justify-content: center;
    align-items: center;
    
    margin: auto;
    flex-direction: column !important;
  }
  #Solo-grid{
  margin: auto;
  }
  }
  